// @ts-nocheck
import React, { HTMLProps, Ref, useMemo, useState } from "react";
import {
	Box,
	Button,
	chakra,
	Checkbox as Check,
	Flex,
	IconButton,
	Table,
	TableContainer,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
// import { ThemeButton } from "@karkhanaui/react";

import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";

import {
	useTable,
	usePagination,
	useSortBy,
	useFilters,
	useRowSelect,
	useGlobalFilter,
	useResizeColumns,
} from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import Checkbox from "./Checkbox";
import { AiOutlineDelete } from "react-icons/ai";

type Column = {
	Header: string;
	accessor: string;
};

type Data = {
	[key: string]: any;
};

type Props = {
	column?: Column[];
	data?: Data[];
	renderCheckBox?: boolean;
	renderSerialNo?: boolean;
	onRowSelect?: any;
	showSelectedRowCount?: boolean;
	isShowSearch?: boolean;
	headerColor?: string;
	paginationbg?: string;
	showItemsAdded?: boolean;
	zeroScreenComponent?: any;
	borderColor?: any;
	placeholderText?: string;
	isPagination?: boolean;
	isStyled?: boolean;
	bgColor?: string;
};

export const GenericTable: React.FC<Props> = ({
	column,
	data,
	renderCheckBox,
	renderSerialNo,
	onRowSelect,
	showSelectedRowCount,
	isShowSearch,
	headerColor,
	paginationbg,
	showItemsAdded,
	zeroScreenComponent,
	borderColor,
	placeholderText,
	isPagination = true,
	isStyled,
	bgColor,
}) => {
	const color = useColorModeValue("#000", "#000");
	const handleNewRowClick = () => {
		// store.push([])
	};

	const {
		getTableProps,
		getTableBodyProps,
		state,
		headerGroups,
		page,
		state: { columnResizing },
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		prepareRow,
		pageCount,
		pageOptions,
		gotoPage,
		selectedFlatRows,
		setPageSize,
		setGlobalFilter,
	} = useTable(
		{
			columns: column,
			data: data,
		},
		useResizeColumns,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect
	);
	const { globalFilter, pageIndex, pageSize } = state;
	const rowData = selectedFlatRows?.map((row: any) => row.original);
	const paginationRange = 2; // number of page numbers to display before and after the current page
	const paginationStart =
		pageIndex - paginationRange < 0 ? 0 : pageIndex - paginationRange;
	const paginationEnd =
		pageIndex + paginationRange >= pageCount
			? pageCount
			: pageIndex + paginationRange + 1;

	const [columnSpan, setColumnSpan] = React.useState<number>(
		column === undefined ? 100 : column?.length + 1
	); // colspan for zero screens
	// to check which rows has been selected
	React.useEffect(() => {
		if (onRowSelect) {
			onRowSelect(selectedFlatRows);
		}
	}, [selectedFlatRows]);

	//incase if the date is a string use date-fns package
	//  in the columns data pass another object called as Cell : ({value}) => {return format(new Date(value), 'dd/mm/yyyy)}
	return (
		<Box overflow="auto">
			<Flex justifyContent={"space-between"} alignItems="center">
				{isShowSearch && (
					<GlobalFilter
						filter={globalFilter}
						setFilter={setGlobalFilter}
						placeholderText={placeholderText}
					/>
				)}
				{showSelectedRowCount && (
					<Text textStyle="primary.secondaryText">
						{selectedFlatRows.length}&nbsp; Selected out of &nbsp;
						{data?.length}
					</Text>
				)}
				{showItemsAdded &&
					(selectedFlatRows.length > 0 ? (
						<Text textStyle="primary.secondaryText">
							{selectedFlatRows.length}&nbsp; Selected out of &nbsp;
							{data?.length}
						</Text>
					) : (
						<Text
							textStyle="primary.secondaryText"
							fontWeight="semibold"
							color="black"
						>
							Items added&nbsp; ({data?.length})
						</Text>
					))}
			</Flex>

			<TableContainer borderRadius="lg">
				<Table
					{...getTableProps}
					bg={bgColor ? "rgba(0, 135, 238, 0.1)" : "transparent"}
					size={"sm"}
					// borderColor={borderColor ? borderColor : "rgba(0, 135, 238, 0.2)"}
				>
					<Thead>
						{headerGroups?.map((headerGroup, headerIndex) => (
							<Tr {...headerGroup.getHeaderGroupProps} key={headerIndex}>
								<Th
									bgColor={headerColor ? headerColor : "rgba(0, 135, 238, 0.1)"}
									border={
										!isStyled
											? borderColor
												? `1px solid ${borderColor}`
												: "1px solid rgba(0, 135, 238, 0.2)"
											: "none"
									}
									textTransform="none"
									color="#000"
									fontFamily="Rubik"
									fontWeight={"medium"}
									textAlign={"left"}
									fontSize={!isStyled ? "14px" : "16pxcd"}
									py={!isStyled ? "0.5em" : "1rem"}
									px="8px"
									letterSpacing={0}
								>
									Sr. No
								</Th>
								{headerGroup.headers?.map((column, index) => (
									<Th
										bgColor={
											headerColor ? headerColor : "rgba(0, 135, 238, 0.1)"
										}
										border={
											!isStyled
												? borderColor
													? `1px solid ${borderColor}`
													: "1px solid rgba(0, 135, 238, 0.2)"
												: "none"
										}
										textTransform="none"
										{...column.getHeaderProps(column.getSortByToggleProps())}
										color="#000"
										fontFamily="Rubik"
										fontWeight={"medium"}
										textAlign={"left"}
										fontSize={!isStyled ? "14px" : "16pxcd"}
										py={!isStyled ? "0.5em" : "1rem"}
										px="8px"
										letterSpacing={0}
									>
										<Box
											p="0.5em 0.3em"
											fontFamily="Rubik"
											fontWeight="semibold"
											display={"flex"}
											flexDirection="row"
											alignItems="center"
											gap="1rem"
										>
											<span>{column.render("Header")}</span>
											{!column.disableSortBy && ( // Check if sorting is enabled for the column
												<span
													onClick={() => column.toggleSortBy()}
													style={{ cursor: "pointer" }}
												>
													{column.isSorted ? (
														column.isSortedDesc ? (
															<BsCaretDownFill size="13px" color="#0087EE" />
														) : (
															<BsCaretUpFill size="13px" color="#0087EE" />
														)
													) : (
														<Box>
															<BsCaretUpFill size="13px" color="#0087EE" />
															<BsCaretDownFill size="13px" color="gray" />
														</Box>
													)}
												</span>
											)}
										</Box>
									</Th>
								))}
							</Tr>
						))}
					</Thead>

					<Tbody {...getTableBodyProps()} bg="#fff">
						{page?.length > 0 ? (
							page.map((row, index) => {
								prepareRow(row);
								return (
									<Tr {...row.getRowProps()} key={index}>
										<Td
											border={
												!isStyled
													? borderColor
														? `1px solid ${borderColor}`
														: "1px solid rgba(0, 135, 238, 0.2)"
													: "none"
											}
											borderBottom={
												isStyled
													? borderColor
														? `1px solid ${borderColor}`
														: "1px solid rgba(0, 135, 238, 0.2)"
													: "none"
											}
											paddingX="5px"
											color={color}
										>
											{renderCheckBox ? (
												<Flex alignItems="center" gap=".25rem">
													<Text
														ml="0.4em"
														p="0.3em"
														textStyle={"primary.text"}
														fontWeight="normal"
													>
														{index + pageIndex * pageSize + 1}.
													</Text>
													<Checkbox {...row.getToggleRowSelectedProps()} />
												</Flex>
											) : (
												<Text
													ml="0.4em"
													p="0.3em"
													textStyle={"primary.text"}
													fontWeight="normal"
												>
													{index + pageIndex * pageSize + 1}
												</Text>
											)}
										</Td>
										{row.cells?.map((cell, i) => (
											<Td
												{...cell.getCellProps()}
												border={
													!isStyled
														? borderColor
															? `1px solid ${borderColor}`
															: "1px solid rgba(0, 135, 238, 0.2)"
														: "none"
												}
												borderBottom={
													isStyled
														? borderColor
															? `1px solid ${borderColor}`
															: "1px solid rgba(0, 135, 238, 0.2)"
														: "none"
												}
												paddingX="5px"
												color={color}
											>
												<Text
													ml="0.4em"
													p="0.3em"
													textStyle={"primary.text"}
													fontWeight="normal"
													display={"flex"}
													flexWrap={"wrap"}
													as="span"
												>
													<span>{cell.render("Cell")}</span>
												</Text>
											</Td>
										))}
									</Tr>
								);
							})
						) : (
							<Tr>
								<Td
									colSpan={columnSpan}
									borderRight={
										borderColor
											? `1px solid ${borderColor}`
											: "1px solid rgba(0, 135, 238, 0.2)"
									}
									borderLeft={
										borderColor
											? `1px solid ${borderColor}`
											: "1px solid rgba(0, 135, 238, 0.2)"
									}
								>
									<Flex
										width="100%"
										margin="2rem 0"
										justifyContent="center"
										alignItems="center"
										backgroundColor="#fff"
										height="100%"
									>
										{zeroScreenComponent}
									</Flex>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
			{/* <button onClick={handleNewRowClick}>Add Row</button> */}
			{isPagination === true && (
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					flexWrap="wrap"
					my="1em"
					pos={"relative"}
					position={"static"}
				>
					<Flex>
						<Text
							mr="0.5em"
							mt="0.1em"
							fontSize={"14px"}
							fontFamily="Rubik"
							color="rgba(94, 98, 120, 1)"
							fontWeight="semibold"
						>
							Page {pageIndex + 1} of {pageOptions.length}
						</Text>
						<React.Fragment>
							<Button
								size="xs"
								bg={
									pageSize === 10
										? paginationbg
											? paginationbg
											: "#0087EE"
										: undefined
								}
								color={pageSize === 10 ? "#fff" : "rgba(94, 98, 120, 1)"}
								_hover={{ opacity: ".8" }}
								onClick={() => setPageSize(10)}
								children="10"
								mr="0.5em"
							/>
							<Button
								color={pageSize === 20 ? "#fff" : "rgba(94, 98, 120, 1)"}
								// bg={pageSize === 20 ? "#0087EE" : undefined}
								bg={
									pageSize === 20
										? paginationbg
											? paginationbg
											: "#0087EE"
										: undefined
								}
								_hover={{ opacity: ".8" }}
								size="xs"
								onClick={() => setPageSize(20)}
								children="20"
								mr="0.5em"
							/>
							<Button
								color={pageSize === 50 ? "#fff" : "rgba(94, 98, 120, 1)"}
								// bg={pageSize === 50 ? "#0087EE" : undefined}
								bg={
									pageSize === 50
										? paginationbg
											? paginationbg
											: "#0087EE"
										: undefined
								}
								_hover={{ opacity: ".8" }}
								size="xs"
								onClick={() => setPageSize(50)}
								children="50"
								mr="0.5em"
							/>
						</React.Fragment>
					</Flex>

					<Flex>
						<Button
							variant="ghost"
							color="#7E8299"
							_hover={{ opacity: ".8" }}
							size="xs"
							ml="0.5em"
							children={"<"}
							onClick={() => gotoPage(0)}
							disabled={!previousPage}
						/>

						{Array.from({ length: paginationEnd - paginationStart }, (_, i) => {
							const page = paginationStart + i;
							return (
								<Button
									size="xs"
									key={i}
									bg={
										pageIndex === page
											? paginationbg
												? paginationbg
												: "#0087EE"
											: undefined
									}
									color={pageIndex === page ? "#fff" : undefined}
									_hover={{ opacity: ".8" }}
									children={page + 1}
									onClick={() => gotoPage(page)}
									ml="0.5em"
								/>
							);
						})}

						<Button
							size="xs"
							ml="0.5em"
							variant="ghost"
							color="#7E8299"
							_hover={{ opacity: ".8" }}
							children={">"}
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!previousPage}
						/>
					</Flex>
				</Box>
			)}
		</Box>
	);
};
